@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
  .modalContainer {
    width: 540px;
    height: 287px;
    background: #25262A;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-color: white;
    border-style: solid;
    border-width: 5px;
    font-family: 'VT323';

  }
.WalletAddress{
    width: 95%;
    height: 25%;
    margin-left: 15px;
    margin-top: 8px;
    background-color:  #222326;
}
.AddressInput{
    width: 452px;
    height:40px;
    object-fit: contain;
    background-color: #222326 ;
    color: #929395;
    font-family: 'VT323';
    border-color:white;
    align-items: center;
    padding-left:24px;
    margin-left: 15px;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 17px;
    background: url(walletlogo.png) no-repeat scroll 450px;
    
}
.WalletConnect{
    height: 50px;
    width: 55%;
    margin-left: 20%;
    margin-top: 8%;
    background-color: rgba(128, 128, 128, 0.221) ;
    border-width: 8px;
}
.connectbutton{
    width: 95%;
    height: 30px;
    margin-top: 10px;
    background-color: transparent;
    color: white;
    border-color: white;
    border-width: 3px;
    text-decoration: #929395;
    font-family: 'VT323';
}
.or{
    margin-left: 175px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'VT323';
    font-size: 20px;
    color: white;
}
.tthebutton{
    display: flex;
width: 213px;
height: 40px;
justify-content: center;
margin-left: 75px;


/* black */

background: #25262A;
/* white */

border: 1px solid #F4F4F4;
border-radius: 5px;
}

.Walletc{
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
/* identical to box height */


/* white */
margin-right: 12px;

color: #F4F4F4;
}
.Walletb{
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 8px;
}