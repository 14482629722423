.All{
    height: 100%;
    width: 100%;
    background-color: #25262A;
    position: fixed;
    overflow-y: scroll;


}
.thisgrid{
    display: grid;
    color: beige;
    grid-template-rows: 88px 183px 183px 183px 400px;
    row-gap: 30px;
    column-gap: 30px;
    grid-template-columns: 350px 350px 350px;
    justify-content: center;
    margin-right: 5px;

}

.Logo{
height: 34px;
width: 76px;
border-radius: 0px;
margin-right: 583px;

}
.thisgrid .theader{
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    border-radius: 0px;
    padding-top: 24px;
    
    
}
.fleft{
    grid-column-end:4 ;
    display: flex;
    height: 40px;
    width: 491px;
    left: 784px;
    top: 24px;
    border-radius: 0px;
    gap: 16PX;
    margin-top: 24px;
}
.Email{
    height: 40px;
    width: 185px;
    left: 306px;
    top: 0px;
    border-radius: 5px;
    padding: 8px, 24px, 8px, 24px;
    
}
.Email img{
height: 16px;
width: 20px;
left: 2px;
top: 4px;
border-radius: 0px;

}
.tWallet{
    height: 40px;
    width: 137px;
    left: 153px;
    top: 0px;
    border-radius: 5px;
    padding: 8px, 28px, 8px, 28px;
    
  }
  .tSupport{
    display: flex;
    border-style: solid;
    border-color: #929395;
    cursor: pointer;
    border-radius: 5px;
    border-width: 1px;
    Width: 137px;
    Height: 40px;
    align-items: center;
    font-size: 20px;
  }
.buttons{
width: 137px;
height: 40px;
border: 1px solid #F4F4F4;
border-radius: 5px;
}
.Discord{
    cursor: pointer;
    display: flex;
    width: 89px;
    height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 24px;
    margin-right: 24px;
    gap: 8.54px;
    align-items: center;
}
.Discord1{
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
color: #F4F4F4;
}
.Discord2{
height: 16.46504783630371px;
width: 22.9248046875px;
}
.Wallet{
    cursor: pointer;
    display: flex;
    width: 89px;
    height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 24px;
    margin-right: 28px;
    gap: 10px;
    align-items: center;
}
.Wallet1{  
    font-family: 'VT323';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #F4F4F4;
}
.Wallet2{
    height: 18px;
    width: 20px;
    left: 2px;
    top: 3px;
    border-radius: 0px;
    

}
.Email{
cursor: pointer;
display: flex;
align-items: center;
gap: 10px;
width: 137px;
height: 24px;
margin-top: 8px;
margin-bottom: 8px;
margin-left: 24px;
margin-right: 24px;
}
.Email1{
    font-family: 'VT323';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #F4F4F4;
}
.Email2{
width: 20px;
height: 16px;
}


.thisgrid .boxx{
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end:3;
    display: flex;
    
}
.gp{

/* 1H */
margin-top: 16px;
margin-left: 16px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 32px;
margin-bottom: 1px;

/* white */

color: #F4F4F4;

}
.thisgrid .boxx .Group{
    object-fit: contain ;
    float: right;
    height: 164px;
width: 169px;
left: 340px;
    margin-top: 10px;
    margin-right: 6px;
    margin-bottom: 1px;


}
.thisgrid .boxx1{
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end:3;
}
.thisgrid .boxx1 .bx1 p{
margin-left: 16px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
}
.thisgrid .boxx1 .Frame{ 
height: 129px;
width: 139px;
left: 744px;
top: 205px;
border-radius: 0px;
object-fit: contain;
float: right;
}
.thisgrid .boxx12{
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end:4;
}
.thisgrid .boxx12 .fc .cc1{
width: 132px;
height: 32px;
left: 941px;
top: 207px;
background: #4159B7;
border: 1px solid #F4F4F4;
border-radius: 5px;
margin-bottom: 8px;
display: flex;
}
.thisgrid .boxx12 .fc .cc2{
    width: 132px;
    height: 32px;
    left: 941px;
    top: 207px;
    background: #C23E3E;
    ;
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    margin-bottom: 8px;
    }
    .thisgrid .boxx12 .fc .cc3{
        width: 132px;
        height: 32px;
        left: 941px;
        top: 207px;
        background: #863EC2;
        border: 1px solid #F4F4F4;
        border-radius: 5px;
        margin-bottom: 8px;
        }
        .thisgrid .boxx12 .fc .cc4{
            width: 132px;
            height: 32px;
            left: 941px;
            top: 207px;
            background: #C28E3E;
            border: 1px solid #F4F4F4;
            border-radius: 5px;
            margin-bottom:8px ;
            }
.thisgrid .boxx12 .fc .ccc{
    width: 132px;
    height: 80px; 
    font-family: 'VT323';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 37px;

}
.thisgrid .boxx12 .fsc{
    display: flex;
    gap: 16px;
}

.thisgrid .boxx12 .sc .sc1{
width: 74px;
height: 32px;
border: 1px solid #F4F4F4;
border-radius: 5px 16px 16px 5px;
margin-bottom: 8px;
display: flex;
}

.thisgrid .boxx13{
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end:4;
    display: flex;
    gap: 0px;
}
.thisgrid .boxx14{
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end:5;
}
.thisgrid .boxx1 .bx1{
    display: flex;
    margin: 0px;
}
.Group2{
width: 90px;
height: 379px;
margin: 0px;
margin-top: 16px;


}
.mp{
    display: flex;
}

.Group92{
    width: 533px;
    height: 170px;
    border-radius: 4px;

    margin-bottom: 20px;
}

.Frame1{
margin-top: 8.48px ;
width: 116px;
height: 120.52px;
margin-bottom: 0px;
object-fit: cover;
}
.f3{
    display: flex;
    gap: 21px;
}
.thisgrid .boxx15{
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end:5;
}
.Group206{
width: 443px;
height: 166px;
margin-top: 15px;

}
.b5{
    display: flex;
}
.b55{
width: 260px;
height: 32px;
margin-left: 16px;
background: #25262A;
border: 1px solid #F4F4F4;
border-radius: 5px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
color: #F4F4F4;
}
.b555{
    display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 8px;
}
.b551{
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 24px;
    margin-right: 24px;
}
.thisgrid .boxx16{
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 5;
    grid-row-end:6;
}
.t1{
    display: flex;
}
.t11{
width: 350px;
height: 242px;
background: #25262A;
border: 1px solid #F4F4F4;
border-radius: 5px;
}
.t122{
    display: flex;
flex-direction: row;
align-items: flex-start;
padding: 16px;
gap: 10px;
width: 105px;
height: 112px;

}
.t1222{
    box-sizing: border-box;

    /* Auto layout */
    
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 10px;
    
    width: 105px;
    height: 112px;
    
    /* black */
    
    background: #25262A;
    /* white */
    
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    
    /* Inside auto layout */
    
    flex: none;
    order: 1;
    flex-grow: 0;

}
.Frame2{
width: 73px;
height: 80px;
background-size: contain;
}
.last{
    display: flex;
    gap: 10px;
    flex-direction: row;
align-items: flex-start;
margin-left: 10px;
}
.t111{
    display: flex;
    margin-top: 0px;
}
.cccc{
height: 40px;
width: 120px;
border-radius: 5px;
border: 1px solid #F4F4F4;
color: #F4F4F4;
text-align: center;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
/* identical to box height */
/* white */
color: #F4F4F4;
}
.cccc1{
    margin-top: 10px;

}
.fccc{
    display: flex;
}
.sc{
    margin: 0px;
}
.fc{
    margin-right: 14px;
}
.fscc{
    margin-left: 16px;
}
.drip{
    margin-left: 407px;
    margin-top: 14px;
  
}
.drip1{
width: 65px;
height: 20px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
text-align: center;
color: #25262A;
}
.drip2{
width: 58px;
height: 16px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
color: #25262A;
opacity: 0.5;
margin-top: 0px;
}
.g1{
position: absolute;
width: 153px;
height: 40px;
background: #25262A;
border: 1px solid #F4F4F4;
border-radius: 5px;
margin-top: 80px;
margin-left: 16px;
}
.g11{
width: 105px;
height: 20px;
margin-left: 24px;
margin-right: 24px;
margin-top: 10px;
margin-bottom: 10px;
text-align: center;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
/* identical to box height */


/* white */

color: #F4F4F4;
}
.bx11{
width: 129px;
height: 40px;
border: 1px solid #F4F4F4;
border-radius: 5px;
margin-left: 16px;
margin-right: 24px;
margin-top: 10px;
margin-bottom: 10px;
}
.u1{
width: 26px;
height: 16px;
margin-left: 8px;
margin-right: 12px;
margin-top: 8px;
margin-bottom: 8px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
color: #F4F4F4;

}
.u2{
 width: 14px;
height: 16px;
margin-top: 9px;
}
.cc11{
 width: 80px;
height: 16px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
color: #F4F4F4;
margin-left: 24px;
margin-right: 43px;
margin-top: 8px;
margin-bottom: 8px;
}

.drip33{
width: 133.79px;
height: 24px;
margin-top: 0px;
border: 1px solid #F4F4F4;
border-radius: 5px;
margin-bottom:4px ;
color:  #F4F4F4;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
text-align: center;
color: #F4F4F4;
}
.drip3{
margin-left: 375px;
margin-top: 2px;
display: flex;
flex-direction: column;
gap: 0px;
}
.tfv1{
width: 158px;
height: 16px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
color: #929395;
flex: none;
order: 1;
flex-grow: 0;
margin-left: 8px;
margin-bottom: 40px;
}
.tfv2{
display: flex;
flex-direction: column;
text-align: center;
width: 153px;
height: 40px;
left: 181px;
top: 492px;
background: #25262A;
border: 1px solid #F4F4F4;
border-radius: 5px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
color: #F4F4F4;
}
.f33{   
width: 200px;
height: 40px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
color: #F4F4F4;
flex: none;
order: 1;
flex-grow: 0;
margin-left: 16px;
margin-top: 8px;
}
.f333{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 153px;
    height: 40px;
    left: 181px;
    top: 492px;
    background: #25262A;
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    font-family: 'VT323';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #F4F4F4;

margin-left: 16px;
margin-top: 31px;

}

.f35{
width: 83px;
height: 57px;
background: #F4F4F4;
margin-left: 14px;
margin-top: 52.52px;
border: 1px solid #F4F4F4;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
display: flex;
align-items: center;
text-align: center;
color: #25262A;
}
.t1111{
width: 89px;
height: 40px;
background: #25262A;
border: 1px solid #F4F4F4;
border-radius: 5px;
margin-top: 90px;
margin-left: 16px;
}
.Frame3{
    width: 185px;
height: 195px;
    margin-bottom: 2px;
    margin-right: 0px;
}
.t113{
    margin-left: 16px;
    font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
/* identical to box height */
width: 150px;


/* MidTone */

color: #929395;
}
.g112{
    width: 41px;
height: 20px;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 24px;
margin-left: 24px;
text-align: center;
/* 2H */

font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
/* identical to box height */


/* white */

color: #F4F4F4;
}
.f36{
    width: 52px;
    height: 38px; 
    background: #F4F4F4;
    color: #25262A;
font-family: VT323;
font-size: 16px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: center;
margin-top: 31px;
margin-left: 9px;
margin-right: 7px;

}
.toggle{
    display: flex;
}
.lbutton{
 width: 65px;
height: 24px;
align-items: center;
background: #25262A;
text-align: center;
border: 1px solid #F4F4F4;
border-radius: 5px;
margin-right: 4px;
}
.dbutton{
    width: 65px;
    height: 24px;
    align-items: center;
    text-align: center;
    background: #25262A;  
    border: 1px solid #F4F4F4;
    border-radius: 5px;

}
.LP{
width: 35px;
height: 16px;
left: 751px;
top: 440px;

/* 3H */
margin-left: 10px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
/* identical to box height */

text-align: center;

/* white */

color: #F4F4F4;
}
.tfv{
    margin-left: 7px;
    margin-right: 0px;
    
}
.gpp{
font-family: 'VT323';
font-style: normal;
font-size: 29px;
color: #F4F4F4;
margin-top: 16px;
}
.g111{
    margin-top: 10px;
    margin-left: 24px;
}
.footer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 68px;
    
    width: 1110px;
    height: 20px;
    left: 164px;
    top: 972px;
    color: gray;
    margin-left: 164px;
    margin-top: 32px;
    }
    .thefooter a{
        color: white;
        text-decoration: none;
      }

.gpT{
 margin-top: 16px;
justify-content: center;
align-self: center;
text-align: center;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 32px;
margin-bottom: 1px;

/* white */

color: #F4F4F4;
}
.cd{
    margin-top: 4px;
}
.dl{
    display: flex;
    margin-bottom: 0px;
    height: 30px;
    margin-top: 3px;
}
