
.thegrid{
  display: grid;
  height: 100%;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-auto-flow: dense;
  justify-content: center;
  column-gap: 15px;
  row-gap: 10px;

}
           

