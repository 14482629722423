.App {
    text-align: center;
  }
  
  
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;

  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  

  .Row1{
  
    display: flex;
  }

 
  .hometable{
    margin-right: 20%;
    margin-left: 20%;
  

  }
  
  
  tr, td{
    
      border: 1px solid black;
    
    
    margin-bottom: 10px;
    background-color: grey;
    
  }
  td{
   
    width: 250px;
    height: 50px;
  
  }
  .balance{
  
    border: none;
    margin: 0px;
  
  
  }
  #fcolumn{
    background-color: black;
    color: white;
  }
  #tcolumn{
    color: white;
  }
  .head{
    display: flex;
    background-color: gray;
    color: aliceblue;
  }
  .head .title{
    width: 90%;
  }
  .head .connect{
    margin-left: 10px;
  }
  #alert{
    background-color: aqua;
  }


 