@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
  .main{
    background: #25262A;
    color: #ffffff;
    padding: 1%;
    height: 100%;
    width: 100%;
    position: fixed;
    font-family: 'VT323';
    font-size: 12px;
    overflow-y: scroll;
    overflow-x: scroll;
  }
  .thegrid {
    height: 95%;
    width: 95%;
    grid-template-columns: repeat(2, 1fr );
    grid-template-rows: 100px 200px 200px 60px;
    grid-column-gap: 0.5%;
    margin:2px;
    object-fit: contain; 
    margin-left: 0px;
    row-gap: 1%;
  }
  .Header{
    display: flex;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;  
  }
  button{
    cursor: pointer;
    border-radius: 5px;
    border-width: 1px;
    font-size: 20px;
  }
 
  .box{
    border-radius: 5px;;
    border-color:#929395;
    border: 1px solid #F4F4F4;
    color: #929395;
    
  }
  button{
width: 137px;
height: 40px;
left: 181px;
top: 279px;
background: #25262A;
border: 1px solid #F4F4F4;
border-radius: 5px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
margin-bottom: 16px;
color: #F4F4F4;

  }
 
  .box .boxheader{
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  color: #F4F4F4;
  margin-top: 16px;
  margin-left: 16px;
  
  }
  .box .box11{
    display: flex;
  
  }
  .c1{
    width: 100px;
    height: 110px;

margin-top: 17px ;

  
  }
  .c3{
    width: 150px;
    height: 135px;
    object-fit: contain;


  
  }


  .box .box12{
    display: flex;
    object-fit: contain;
  }
  .box .box12 img{
  object-fit: contain;
  width: 53px;
  Height: 80px;
  
  margin-left: 0px;     
  }
  .box .box12 button{
    font-size: 20px;
    width: 100px;
  }
  .box .box13{
    display: flex;
    
  }
  .box .box14{
    display: flex;
    
  }
 
 
  
  .Footer{
    display: flex;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 5;
    grid-row-end: 6;
    font-size: 8px;  
  } 
  .Footer a{
    color: white;
    text-decoration: none;
  }
  .Footer div{
    color: #929395;
  }


.thegrid {
  height: 100%;
  width: 100%;
  grid-template-columns: 350px 350px 350px;
  grid-template-rows: 88px 183px 183px 10px;
  grid-column-gap: 30px;
  grid-row-gap:30px;
  padding:6px;
  object-fit: contain; 
  margin-left: 0px; 
  font-size: 14px;  
}
.Header{
  display: flex;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;  
} 
.ttheLogo{
  width: 76px;
  height: 34px;
  margin-right: 744px;

} 
.Footer{
  display: flex;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 4;
  grid-row-end: 5;
  justify-content: space-evenly;
  font-size: medium;  
} 
.box .boxheader{
  font-size: 32px;
  font-style: normal;
  font-family: 'VT323';
}
.box{
  font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;

/* MidTone */

color: #929395;
}

.box .box12 img{
  width: 116px;
height: 130px;
margin-bottom: 16px;
margin-top: 0px;
  
}

.boxm{
  margin-left: 16px;
  width: 170px;
  margin-right: 20px;
}

.frontfooter{
  display: flex;
  padding: 0px;
  gap: 68px;
  width: 1110px;
  height: 20px;
  margin: 0 auto;
  color: gray;
  align-items: center;
  justify-content: center;
  margin-top: 424px;
  
  }
  .frontfooter a{
    color: white;
    text-decoration: none;
    margin: 0 auto;
  }

  .c4{
    height:130px;
    width:67px;
    margin-left: 10px;
}

