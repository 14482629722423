.themain{
background: #25262A;
height: 100%;
width: 100%;
position: fixed;
overflow-y: scroll;
justify-content: center;
}
.bheader{
    display: flex;
    flex-direction: row;
    padding-top: 24px;
    margin: 0 auto;
    justify-content: center;
}
.theLogo{
width: 76px;
height: 34px;
left: 165px;
top: 27px;
margin-right: 744px;


}
.awaits{

display: flex;
width: 270px;
height: 32px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 32px;
line-height: 32px;
text-align: center;
color: #F4F4F4;
margin-top: 64px;
margin: 0 auto;

}
.body{
width: 730px;
height: 506px;
margin: 0 auto;
/* black */

background: #25262A;
/* white */

border: 1px solid #F4F4F4;
border-radius: 5px;
}
.innerbody{
    width: 681px;
    height: 458px;
left: 380px;
top: 176px;
background: url("./images/Await.png");
border-radius: 5px;
margin-left: 25px;
margin-top: 24px;
}
.connection{
    display: flex;
    margin: 0 auto;
  
    justify-content: center;
    margin-top: 14px;
    
}
.addressinput{
display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-start;
width: 480px;
height: 40px;
background: #25262A;

border: 1px solid #F4F4F4;
border-radius: 5px;

    
}
.theAddressInput{
    width: 452px;
    height:40px;
    background-color: #222326 ;
    color: #929395;
    font-family: 'VT323';
    border-color:white;
    align-items: center;
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    font-size: 17px;
    background: url(../src/Components/walletlogo.png) no-repeat scroll 430px 5px;
    background-position: 450px;
    display: flex;
    padding-left: 24px;
    
    
}
.theor{
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: 17px;
height: 20px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
/* identical to box height */


color: #FFFFFF;
margin-left: 16px;
margin-right: 16px;
}
.thebutton{
    display: flex;
    width: 201px;
    height: 40px;
justify-content: center;
cursor: pointer;


/* black */

background: #25262A;
/* white */

border: 1px solid #F4F4F4;
border-radius: 5px;
}

.Walletc{
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
/* identical to box height */


/* white */
margin-right: 12px;

color: #F4F4F4;
}
.Walletb{
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 8px;
}
.thefooter{
display: flex;
padding: 0px;
gap: 68px;
width: 1110px;
height: 20px;
margin: 0 auto;
color: gray;
align-items: center;
justify-content: center;
margin-top: 24px;

}
.footer a{
    color: white;
    text-decoration: none;
    margin: 0 auto;
  }