@import url(https://fonts.googleapis.com/css2?family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css2?family=VT323&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.thegrid{
  display: grid;
  height: 100%;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-auto-flow: dense;
  justify-content: center;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  grid-row-gap: 10px;
  row-gap: 10px;

}
           


.main{
    background: #25262A;
    color: #ffffff;
    padding: 1%;
    height: 100%;
    width: 100%;
    position: fixed;
    font-family: 'VT323';
    font-size: 12px;
    overflow-y: scroll;
    overflow-x: scroll;
  }
  .thegrid {
    height: 95%;
    width: 95%;
    grid-template-columns: repeat(2, 1fr );
    grid-template-rows: 100px 200px 200px 60px;
    grid-column-gap: 0.5%;
    margin:2px;
    object-fit: contain; 
    margin-left: 0px;
    grid-row-gap: 1%;
    row-gap: 1%;
  }
  .Header{
    display: flex;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;  
  }
  button{
    cursor: pointer;
    border-radius: 5px;
    border-width: 1px;
    font-size: 20px;
  }
 
  .box{
    border-radius: 5px;;
    border-color:#929395;
    border: 1px solid #F4F4F4;
    color: #929395;
    
  }
  button{
width: 137px;
height: 40px;
left: 181px;
top: 279px;
background: #25262A;
border: 1px solid #F4F4F4;
border-radius: 5px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
margin-bottom: 16px;
color: #F4F4F4;

  }
 
  .box .boxheader{
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  color: #F4F4F4;
  margin-top: 16px;
  margin-left: 16px;
  
  }
  .box .box11{
    display: flex;
  
  }
  .c1{
    width: 100px;
    height: 110px;

margin-top: 17px ;

  
  }
  .c3{
    width: 150px;
    height: 135px;
    object-fit: contain;


  
  }


  .box .box12{
    display: flex;
    object-fit: contain;
  }
  .box .box12 img{
  object-fit: contain;
  width: 53px;
  Height: 80px;
  
  margin-left: 0px;     
  }
  .box .box12 button{
    font-size: 20px;
    width: 100px;
  }
  .box .box13{
    display: flex;
    
  }
  .box .box14{
    display: flex;
    
  }
 
 
  
  .Footer{
    display: flex;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 5;
    grid-row-end: 6;
    font-size: 8px;  
  } 
  .Footer a{
    color: white;
    text-decoration: none;
  }
  .Footer div{
    color: #929395;
  }


.thegrid {
  height: 100%;
  width: 100%;
  grid-template-columns: 350px 350px 350px;
  grid-template-rows: 88px 183px 183px 10px;
  grid-column-gap: 30px;
  grid-row-gap:30px;
  padding:6px;
  object-fit: contain; 
  margin-left: 0px; 
  font-size: 14px;  
}
.Header{
  display: flex;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;  
} 
.ttheLogo{
  width: 76px;
  height: 34px;
  margin-right: 744px;

} 
.Footer{
  display: flex;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 4;
  grid-row-end: 5;
  justify-content: space-evenly;
  font-size: medium;  
} 
.box .boxheader{
  font-size: 32px;
  font-style: normal;
  font-family: 'VT323';
}
.box{
  font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;

/* MidTone */

color: #929395;
}

.box .box12 img{
  width: 116px;
height: 130px;
margin-bottom: 16px;
margin-top: 0px;
  
}

.boxm{
  margin-left: 16px;
  width: 170px;
  margin-right: 20px;
}

.frontfooter{
  display: flex;
  padding: 0px;
  grid-gap: 68px;
  gap: 68px;
  width: 1110px;
  height: 20px;
  margin: 0 auto;
  color: gray;
  align-items: center;
  justify-content: center;
  margin-top: 424px;
  
  }
  .frontfooter a{
    color: white;
    text-decoration: none;
    margin: 0 auto;
  }

  .c4{
    height:130px;
    width:67px;
    margin-left: 10px;
}


.App {
    text-align: center;
  }
  
  
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      -webkit-animation: App-logo-spin infinite 20s linear;
              animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;

  }
  
  .App-link {
    color: #61dafb;
  }
  
  @-webkit-keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  

  .Row1{
  
    display: flex;
  }

 
  .hometable{
    margin-right: 20%;
    margin-left: 20%;
  

  }
  
  
  tr, td{
    
      border: 1px solid black;
    
    
    margin-bottom: 10px;
    background-color: grey;
    
  }
  td{
   
    width: 250px;
    height: 50px;
  
  }
  .balance{
  
    border: none;
    margin: 0px;
  
  
  }
  #fcolumn{
    background-color: black;
    color: white;
  }
  #tcolumn{
    color: white;
  }
  .head{
    display: flex;
    background-color: gray;
    color: aliceblue;
  }
  .head .title{
    width: 90%;
  }
  .head .connect{
    margin-left: 10px;
  }
  #alert{
    background-color: aqua;
  }


 
.modalContainer {
    width: 540px;
    height: 287px;
    background: #25262A;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-color: white;
    border-style: solid;
    border-width: 5px;
    font-family: 'VT323';

  }
.WalletAddress{
    width: 95%;
    height: 25%;
    margin-left: 15px;
    margin-top: 8px;
    background-color:  #222326;
}
.AddressInput{
    width: 452px;
    height:40px;
    object-fit: contain;
    background-color: #222326 ;
    color: #929395;
    font-family: 'VT323';
    border-color:white;
    align-items: center;
    padding-left:24px;
    margin-left: 15px;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 17px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABYSURBVHgBxZSrDgAgCEXFGfn/L6XrCBSCwMbjFIuDwx0DiOiuBBAR+N0rmaM7RNET1hl6Dax//YaClXGZYbggm/xynctQsLKcM/TuY7+hd//qr03WXUw3fGbdJ54dh2wAAAAAAElFTkSuQmCC) no-repeat scroll 450px;
    
}
.WalletConnect{
    height: 50px;
    width: 55%;
    margin-left: 20%;
    margin-top: 8%;
    background-color: rgba(128, 128, 128, 0.221) ;
    border-width: 8px;
}
.connectbutton{
    width: 95%;
    height: 30px;
    margin-top: 10px;
    background-color: transparent;
    color: white;
    border-color: white;
    border-width: 3px;
    -webkit-text-decoration: #929395;
            text-decoration: #929395;
    font-family: 'VT323';
}
.or{
    margin-left: 175px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'VT323';
    font-size: 20px;
    color: white;
}
.tthebutton{
    display: flex;
width: 213px;
height: 40px;
justify-content: center;
margin-left: 75px;


/* black */

background: #25262A;
/* white */

border: 1px solid #F4F4F4;
border-radius: 5px;
}

.Walletc{
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
/* identical to box height */


/* white */
margin-right: 12px;

color: #F4F4F4;
}
.Walletb{
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 8px;
}
.All{
    height: 100%;
    width: 100%;
    background-color: #25262A;
    position: fixed;
    overflow-y: scroll;


}
.thisgrid{
    display: grid;
    color: beige;
    grid-template-rows: 88px 183px 183px 183px 400px;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    grid-template-columns: 350px 350px 350px;
    justify-content: center;
    margin-right: 5px;

}

.Logo{
height: 34px;
width: 76px;
border-radius: 0px;
margin-right: 583px;

}
.thisgrid .theader{
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    border-radius: 0px;
    padding-top: 24px;
    
    
}
.fleft{
    grid-column-end:4 ;
    display: flex;
    height: 40px;
    width: 491px;
    left: 784px;
    top: 24px;
    border-radius: 0px;
    grid-gap: 16PX;
    gap: 16PX;
    margin-top: 24px;
}
.Email{
    height: 40px;
    width: 185px;
    left: 306px;
    top: 0px;
    border-radius: 5px;
    padding: 8px, 24px, 8px, 24px;
    
}
.Email img{
height: 16px;
width: 20px;
left: 2px;
top: 4px;
border-radius: 0px;

}
.tWallet{
    height: 40px;
    width: 137px;
    left: 153px;
    top: 0px;
    border-radius: 5px;
    padding: 8px, 28px, 8px, 28px;
    
  }
  .tSupport{
    display: flex;
    border-style: solid;
    border-color: #929395;
    cursor: pointer;
    border-radius: 5px;
    border-width: 1px;
    Width: 137px;
    Height: 40px;
    align-items: center;
    font-size: 20px;
  }
.buttons{
width: 137px;
height: 40px;
border: 1px solid #F4F4F4;
border-radius: 5px;
}
.Discord{
    cursor: pointer;
    display: flex;
    width: 89px;
    height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 24px;
    margin-right: 24px;
    grid-gap: 8.54px;
    gap: 8.54px;
    align-items: center;
}
.Discord1{
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
color: #F4F4F4;
}
.Discord2{
height: 16.46504783630371px;
width: 22.9248046875px;
}
.Wallet{
    cursor: pointer;
    display: flex;
    width: 89px;
    height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 24px;
    margin-right: 28px;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}
.Wallet1{  
    font-family: 'VT323';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #F4F4F4;
}
.Wallet2{
    height: 18px;
    width: 20px;
    left: 2px;
    top: 3px;
    border-radius: 0px;
    

}
.Email{
cursor: pointer;
display: flex;
align-items: center;
grid-gap: 10px;
gap: 10px;
width: 137px;
height: 24px;
margin-top: 8px;
margin-bottom: 8px;
margin-left: 24px;
margin-right: 24px;
}
.Email1{
    font-family: 'VT323';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #F4F4F4;
}
.Email2{
width: 20px;
height: 16px;
}


.thisgrid .boxx{
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end:3;
    display: flex;
    
}
.gp{

/* 1H */
margin-top: 16px;
margin-left: 16px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 32px;
margin-bottom: 1px;

/* white */

color: #F4F4F4;

}
.thisgrid .boxx .Group{
    object-fit: contain ;
    float: right;
    height: 164px;
width: 169px;
left: 340px;
    margin-top: 10px;
    margin-right: 6px;
    margin-bottom: 1px;


}
.thisgrid .boxx1{
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end:3;
}
.thisgrid .boxx1 .bx1 p{
margin-left: 16px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
}
.thisgrid .boxx1 .Frame{ 
height: 129px;
width: 139px;
left: 744px;
top: 205px;
border-radius: 0px;
object-fit: contain;
float: right;
}
.thisgrid .boxx12{
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end:4;
}
.thisgrid .boxx12 .fc .cc1{
width: 132px;
height: 32px;
left: 941px;
top: 207px;
background: #4159B7;
border: 1px solid #F4F4F4;
border-radius: 5px;
margin-bottom: 8px;
display: flex;
}
.thisgrid .boxx12 .fc .cc2{
    width: 132px;
    height: 32px;
    left: 941px;
    top: 207px;
    background: #C23E3E;
    ;
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    margin-bottom: 8px;
    }
    .thisgrid .boxx12 .fc .cc3{
        width: 132px;
        height: 32px;
        left: 941px;
        top: 207px;
        background: #863EC2;
        border: 1px solid #F4F4F4;
        border-radius: 5px;
        margin-bottom: 8px;
        }
        .thisgrid .boxx12 .fc .cc4{
            width: 132px;
            height: 32px;
            left: 941px;
            top: 207px;
            background: #C28E3E;
            border: 1px solid #F4F4F4;
            border-radius: 5px;
            margin-bottom:8px ;
            }
.thisgrid .boxx12 .fc .ccc{
    width: 132px;
    height: 80px; 
    font-family: 'VT323';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 37px;

}
.thisgrid .boxx12 .fsc{
    display: flex;
    grid-gap: 16px;
    gap: 16px;
}

.thisgrid .boxx12 .sc .sc1{
width: 74px;
height: 32px;
border: 1px solid #F4F4F4;
border-radius: 5px 16px 16px 5px;
margin-bottom: 8px;
display: flex;
}

.thisgrid .boxx13{
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end:4;
    display: flex;
    grid-gap: 0px;
    gap: 0px;
}
.thisgrid .boxx14{
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end:5;
}
.thisgrid .boxx1 .bx1{
    display: flex;
    margin: 0px;
}
.Group2{
width: 90px;
height: 379px;
margin: 0px;
margin-top: 16px;


}
.mp{
    display: flex;
}

.Group92{
    width: 533px;
    height: 170px;
    border-radius: 4px;

    margin-bottom: 20px;
}

.Frame1{
margin-top: 8.48px ;
width: 116px;
height: 120.52px;
margin-bottom: 0px;
object-fit: cover;
}
.f3{
    display: flex;
    grid-gap: 21px;
    gap: 21px;
}
.thisgrid .boxx15{
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end:5;
}
.Group206{
width: 443px;
height: 166px;
margin-top: 15px;

}
.b5{
    display: flex;
}
.b55{
width: 260px;
height: 32px;
margin-left: 16px;
background: #25262A;
border: 1px solid #F4F4F4;
border-radius: 5px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
color: #F4F4F4;
}
.b555{
    display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
grid-gap: 8px;
gap: 8px;
}
.b551{
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 24px;
    margin-right: 24px;
}
.thisgrid .boxx16{
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 5;
    grid-row-end:6;
}
.t1{
    display: flex;
}
.t11{
width: 350px;
height: 242px;
background: #25262A;
border: 1px solid #F4F4F4;
border-radius: 5px;
}
.t122{
    display: flex;
flex-direction: row;
align-items: flex-start;
padding: 16px;
grid-gap: 10px;
gap: 10px;
width: 105px;
height: 112px;

}
.t1222{
    box-sizing: border-box;

    /* Auto layout */
    
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    grid-gap: 10px;
    gap: 10px;
    
    width: 105px;
    height: 112px;
    
    /* black */
    
    background: #25262A;
    /* white */
    
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    
    /* Inside auto layout */
    
    flex: none;
    order: 1;
    flex-grow: 0;

}
.Frame2{
width: 73px;
height: 80px;
background-size: contain;
}
.last{
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    flex-direction: row;
align-items: flex-start;
margin-left: 10px;
}
.t111{
    display: flex;
    margin-top: 0px;
}
.cccc{
height: 40px;
width: 120px;
border-radius: 5px;
border: 1px solid #F4F4F4;
color: #F4F4F4;
text-align: center;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
/* identical to box height */
/* white */
color: #F4F4F4;
}
.cccc1{
    margin-top: 10px;

}
.fccc{
    display: flex;
}
.sc{
    margin: 0px;
}
.fc{
    margin-right: 14px;
}
.fscc{
    margin-left: 16px;
}
.drip{
    margin-left: 407px;
    margin-top: 14px;
  
}
.drip1{
width: 65px;
height: 20px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
text-align: center;
color: #25262A;
}
.drip2{
width: 58px;
height: 16px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
color: #25262A;
opacity: 0.5;
margin-top: 0px;
}
.g1{
position: absolute;
width: 153px;
height: 40px;
background: #25262A;
border: 1px solid #F4F4F4;
border-radius: 5px;
margin-top: 80px;
margin-left: 16px;
}
.g11{
width: 105px;
height: 20px;
margin-left: 24px;
margin-right: 24px;
margin-top: 10px;
margin-bottom: 10px;
text-align: center;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
/* identical to box height */


/* white */

color: #F4F4F4;
}
.bx11{
width: 129px;
height: 40px;
border: 1px solid #F4F4F4;
border-radius: 5px;
margin-left: 16px;
margin-right: 24px;
margin-top: 10px;
margin-bottom: 10px;
}
.u1{
width: 26px;
height: 16px;
margin-left: 8px;
margin-right: 12px;
margin-top: 8px;
margin-bottom: 8px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
color: #F4F4F4;

}
.u2{
 width: 14px;
height: 16px;
margin-top: 9px;
}
.cc11{
 width: 80px;
height: 16px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
color: #F4F4F4;
margin-left: 24px;
margin-right: 43px;
margin-top: 8px;
margin-bottom: 8px;
}

.drip33{
width: 133.79px;
height: 24px;
margin-top: 0px;
border: 1px solid #F4F4F4;
border-radius: 5px;
margin-bottom:4px ;
color:  #F4F4F4;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
text-align: center;
color: #F4F4F4;
}
.drip3{
margin-left: 375px;
margin-top: 2px;
display: flex;
flex-direction: column;
grid-gap: 0px;
gap: 0px;
}
.tfv1{
width: 158px;
height: 16px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
color: #929395;
flex: none;
order: 1;
flex-grow: 0;
margin-left: 8px;
margin-bottom: 40px;
}
.tfv2{
display: flex;
flex-direction: column;
text-align: center;
width: 153px;
height: 40px;
left: 181px;
top: 492px;
background: #25262A;
border: 1px solid #F4F4F4;
border-radius: 5px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
color: #F4F4F4;
}
.f33{   
width: 200px;
height: 40px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
color: #F4F4F4;
flex: none;
order: 1;
flex-grow: 0;
margin-left: 16px;
margin-top: 8px;
}
.f333{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 153px;
    height: 40px;
    left: 181px;
    top: 492px;
    background: #25262A;
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    font-family: 'VT323';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #F4F4F4;

margin-left: 16px;
margin-top: 31px;

}

.f35{
width: 83px;
height: 57px;
background: #F4F4F4;
margin-left: 14px;
margin-top: 52.52px;
border: 1px solid #F4F4F4;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
display: flex;
align-items: center;
text-align: center;
color: #25262A;
}
.t1111{
width: 89px;
height: 40px;
background: #25262A;
border: 1px solid #F4F4F4;
border-radius: 5px;
margin-top: 90px;
margin-left: 16px;
}
.Frame3{
    width: 185px;
height: 195px;
    margin-bottom: 2px;
    margin-right: 0px;
}
.t113{
    margin-left: 16px;
    font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
/* identical to box height */
width: 150px;


/* MidTone */

color: #929395;
}
.g112{
    width: 41px;
height: 20px;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 24px;
margin-left: 24px;
text-align: center;
/* 2H */

font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
/* identical to box height */


/* white */

color: #F4F4F4;
}
.f36{
    width: 52px;
    height: 38px; 
    background: #F4F4F4;
    color: #25262A;
font-family: VT323;
font-size: 16px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: center;
margin-top: 31px;
margin-left: 9px;
margin-right: 7px;

}
.toggle{
    display: flex;
}
.lbutton{
 width: 65px;
height: 24px;
align-items: center;
background: #25262A;
text-align: center;
border: 1px solid #F4F4F4;
border-radius: 5px;
margin-right: 4px;
}
.dbutton{
    width: 65px;
    height: 24px;
    align-items: center;
    text-align: center;
    background: #25262A;  
    border: 1px solid #F4F4F4;
    border-radius: 5px;

}
.LP{
width: 35px;
height: 16px;
left: 751px;
top: 440px;

/* 3H */
margin-left: 10px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
/* identical to box height */

text-align: center;

/* white */

color: #F4F4F4;
}
.tfv{
    margin-left: 7px;
    margin-right: 0px;
    
}
.gpp{
font-family: 'VT323';
font-style: normal;
font-size: 29px;
color: #F4F4F4;
margin-top: 16px;
}
.g111{
    margin-top: 10px;
    margin-left: 24px;
}
.footer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 68px;
    gap: 68px;
    
    width: 1110px;
    height: 20px;
    left: 164px;
    top: 972px;
    color: gray;
    margin-left: 164px;
    margin-top: 32px;
    }
    .thefooter a{
        color: white;
        text-decoration: none;
      }

.gpT{
 margin-top: 16px;
justify-content: center;
align-self: center;
text-align: center;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 32px;
margin-bottom: 1px;

/* white */

color: #F4F4F4;
}
.cd{
    margin-top: 4px;
}
.dl{
    display: flex;
    margin-bottom: 0px;
    height: 30px;
    margin-top: 3px;
}

.themain{
background: #25262A;
height: 100%;
width: 100%;
position: fixed;
overflow-y: scroll;
justify-content: center;
}
.bheader{
    display: flex;
    flex-direction: row;
    padding-top: 24px;
    margin: 0 auto;
    justify-content: center;
}
.theLogo{
width: 76px;
height: 34px;
left: 165px;
top: 27px;
margin-right: 744px;


}
.awaits{

display: flex;
width: 270px;
height: 32px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 32px;
line-height: 32px;
text-align: center;
color: #F4F4F4;
margin-top: 64px;
margin: 0 auto;

}
.body{
width: 730px;
height: 506px;
margin: 0 auto;
/* black */

background: #25262A;
/* white */

border: 1px solid #F4F4F4;
border-radius: 5px;
}
.innerbody{
    width: 681px;
    height: 458px;
left: 380px;
top: 176px;
background: url(/static/media/Await.111b678b.png);
border-radius: 5px;
margin-left: 25px;
margin-top: 24px;
}
.connection{
    display: flex;
    margin: 0 auto;
  
    justify-content: center;
    margin-top: 14px;
    
}
.addressinput{
display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-start;
width: 480px;
height: 40px;
background: #25262A;

border: 1px solid #F4F4F4;
border-radius: 5px;

    
}
.theAddressInput{
    width: 452px;
    height:40px;
    background-color: #222326 ;
    color: #929395;
    font-family: 'VT323';
    border-color:white;
    align-items: center;
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    font-size: 17px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABYSURBVHgBxZSrDgAgCEXFGfn/L6XrCBSCwMbjFIuDwx0DiOiuBBAR+N0rmaM7RNET1hl6Dax//YaClXGZYbggm/xynctQsLKcM/TuY7+hd//qr03WXUw3fGbdJ54dh2wAAAAAAElFTkSuQmCC) no-repeat scroll 430px 5px;
    background-position: 450px;
    display: flex;
    padding-left: 24px;
    
    
}
.theor{
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: 17px;
height: 20px;
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
/* identical to box height */


color: #FFFFFF;
margin-left: 16px;
margin-right: 16px;
}
.thebutton{
    display: flex;
    width: 201px;
    height: 40px;
justify-content: center;
cursor: pointer;


/* black */

background: #25262A;
/* white */

border: 1px solid #F4F4F4;
border-radius: 5px;
}

.Walletc{
font-family: 'VT323';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;
/* identical to box height */


/* white */
margin-right: 12px;

color: #F4F4F4;
}
.Walletb{
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 8px;
}
.thefooter{
display: flex;
padding: 0px;
grid-gap: 68px;
gap: 68px;
width: 1110px;
height: 20px;
margin: 0 auto;
color: gray;
align-items: center;
justify-content: center;
margin-top: 24px;

}
.footer a{
    color: white;
    text-decoration: none;
    margin: 0 auto;
  }
